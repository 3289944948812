import React from "react";
import Acnavbar from "../components/Acnavbar";
import IdeationDescription from "../components/IdeationDescription";
import IdeationBenefits from "../components/IdeationBenefits";
import ConsSection from "../components/ConsSection";
import ComptetitiveSection from "../components/ComptetitiveSection";
import TechnologyIdeation from "../components/TechnologyIdeation";
import Contact from "../components/Contact";
import Footer from "../components/footer";
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";
import ProductBottom from "../components/ProductBottom";

function ServiceDetailTemplate({ pageContext }: any) {
  return (
    <Wrapper>
      <Seo
        title={pageContext.seo_title}
        description={pageContext.seo_description}
      />
      <div className=" overflow-hidden bg-cover">
        <Acnavbar
          home={false}
          pathname={"services"}
          logo={""}
          logoLogic={false}
        />
        <IdeationDescription
          header={pageContext.ideationHomePage.header}
          headerText={pageContext.ideationHomePage.headerText}
          headerImg={""}
          topheader={pageContext.ideationHomePage.topheader}
        />
        <ProductBottom
          bottom_header={pageContext.ideationHomePage.bottom_header}
          bottom_desc={pageContext.ideationHomePage.bottom_desc}
        />
        <IdeationBenefits data={pageContext.benefits} />
        <TechnologyIdeation data={pageContext.technology} />
        <ComptetitiveSection data={pageContext.competetive} />
        <ConsSection data={pageContext.consIdeation} />
        <Contact />
        <Footer />
      </div>
    </Wrapper>
  );
}

export default ServiceDetailTemplate;
