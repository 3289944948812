import React from 'react'
import BenefitCard from './BenefitCard';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { ideationBenefitSection } from '../types/ServiceDetail';

function IdeationBenefits({data}:any) {
    return (
        <div className='ideation-benfits-background pb-lg-36'>
        <Container className='mx-auto pt-lg-5 pt-4 pb-md-3 pb-lg-0'>
          <h3 className='fnt-14 fnt-md-16 text-center color-lightGreen mb-3 color-blue text-uppercase fw-700'>Benefits</h3>
          <h2 className='fw-700 fnt-lg-32 text-center text-black fnt-20 mb-4'>{data.benefitHeader}</h2>
  
          <Row xs={1} sm={1} md={2} lg={3} xl={3}>
            {data.ideationSectionBenefit?.map((benefitcard: ideationBenefitSection) => (
              <Col key={benefitcard.id} className='pb-4'>
                <BenefitCard {...benefitcard} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
}

export default IdeationBenefits;

