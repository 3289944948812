import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"

function BenefitCard({
  id,
  title,
  img,
  desc,
}: {
  id: number;
  title: string;
  img: string;
  desc: string;
}) {
  return (
    <div className="h-100">
      <Card className="h-100">
        <Card.Title>
          <LazyLoadImage effect="blur" className="rounded-top"  src={img}  alt={title} width={"100%"} height={"auto"}/>
        </Card.Title>
        <Card.Body>
          <div className="benefit-title-card">
            <Card.Title className="text-bold fw-700 fnt-18 text-green">
              {title}
            </Card.Title>
          </div>
          <Card.Text className="benefit-card-text fnt-14 fnt-lg-16">
            {desc}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default BenefitCard;
