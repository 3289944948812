import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CompSectionText from "./CompSectionText";
import { Link } from "gatsby";

function ComptetitiveSection({ data }: any) {
  return (
    <div className=" bg-cover bg-white py-4 py-lg-5 technology-section">
      <Container>
        <div className="d-flex fnt-14 fnt-md-16 fnt-lg-18 fnt-md-18 fw-700 justify-content-center textColor text-uppercase">
          Why we?
        </div>
        <div className="color-black d-flex fnt-lg-32 fnt-md-32 fnt-sm-20 fw-700 justify-content-center mb-3">
          What makes us the best choice?
        </div>
        <div className="">
          <Row xs={1} sm={1} md={2} lg={3}>
            {data.text.map((item: any, index: number) => (
              <CompSectionText
                key={index}
                header={item.header}
                headertext={item.headertext}
                headerImg={item.icon}
              />
            ))}
          </Row>
        </div>
        <div className="mb-2 mt-lg-4 text-center">
          <Link to="/contact-us">
            <Button className="btn-contact-us border-0 my-auto">
              Connect Now
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default ComptetitiveSection;
