import React from 'react'
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"

function TechnologyIdeation({ data }: any) {
  return (
    <div className='py-4 py-lg-5 common-background'>
      <Container>
        <h3 className='color-light-green fnt-14 fnt-md-16 fnt-lg-18 lh-28 text-center text-uppercase fw-700'>Technology</h3>
        <p className='fw-700 fnt-lg-32 text-center text-white fnt-md-20 fnt-16  mb-2'>{data.headerText}</p>
        <div className='techsecBody'>
          <div className='techbodyWrap'>
            <div className='technologycol'>
              {data?.technology_content?.map((demo: any, index: any) => (
                <div className='align-items-baseline techcolWrap' key={index}>
                  <Link to={`/blogs/${demo.slug}/`}>
                  <div className='outside_wrap'>
                    <div className=' d-flex flex-row'>
                      <LazyLoadImage effect='blur' src={demo?.image} className="me-3 align-self-start" alt={demo.header} />
                      <div className='technology-text-box'>
                        <h2 className='fnt-lg-18 fnt-md-18 fnt-16 fw-700 lh-24 text-white'>{demo.header}</h2>
                        <p className='fnt-14 fnt-lg-16 fnt-md-16 fw-400 lh-24 text-white'>{demo.headeText}</p>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TechnologyIdeation;